<template>
  <div>
    <Navigation />

    <div class="wrap-box">
      <h3 class="heading">Адміністративно-управлінський персонал</h3>
    </div>

    <section style="background:#B0DAF4;">
      <div class="wrap-box">
        <div class="structure-table-block">
          <table class="table-abonents">
            <tr class="table-header">
              <td>№ п/п</td>
              <td>Абонент</td>
              <td>Кабінет</td>
              <td>П.І.Б.</td>
              <td>Номер</td>
            </tr>
            <tr v-for="row in bossesTable" :key="row.tableIndex">
              <td>{{row.tableIndex}}</td>
              <td>{{row.title}}</td>
              <td>{{row.cabinet}}</td>
              <td>{{row.fullName}}</td>
              <td>{{row.phoneNumber}}</td>
            </tr>
            <!-- <tr>
              <td>1</td>
              <td>Директор</td>
              <td>629</td>
              <td>Трушкіна Людмила Іванівна</td>
              <td>(073) 537-15-10</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Заступник директора з медичного обслуговування</td>
              <td>627</td>
              <td>Гоменюк Ірина Анатоліївна</td>
              <td>(073) 537-10-08</td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Заступник директора з експертизи тимчасової непрацездатності та
                якості лікування
              </td>
              <td>220</td>
              <td>Куртяк Оксана Аркадіївна</td>
              <td>(073) 537-10-34</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Заступник директора з економіки</td>
              <td>604</td>
              <td>Новікова Валентина Іванівна</td>
              <td>(073) 537-10-02</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Заступник директора з дитинства</td>
              <td>412</td>
              <td>Смірнова Наталія Григорівна</td>
              <td>(044) 546-72-45</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Юрисконсульт</td>
              <td>602</td>
              <td>Світлицький Андрій Вікторович</td>
              <td>(073) 537-15-10</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Головний бухгалтер</td>
              <td>606</td>
              <td>Дякончук Людмила Василівна</td>
              <td>(073) 537-10-36</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Головна медична сестра</td>
              <td>630</td>
              <td>Погоріляк Тетяна Олександрівна</td>
              <td>(073) 537-10-06</td>
            </tr>
            <tr>
              <td rowspan="2">9</td>
              <td>Головний інженер</td>
              <td rowspan="2">628</td>
              <td>Шевяков Сергій Володимирович</td>
              <td rowspan="2">(073) 537-10-03</td>
            </tr>
            <tr>
              <td>
                Інженер з охорони
                праці
              </td>
              <td>
                Пронь
                Олена
                Володимирівна
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Начальник відділу кадрів</td>
              <td>626</td>
              <td>Левицька Вікторія Миколаївна</td>
              <td>(073) 537-10-17</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Інформаційно аналітичний відділ</td>
              <td>622</td>
              <td>Бурик Юлія Жоржевна Єщенко Наталія Василівна</td>
              <td>(073) 537-10-35</td>
            </tr>-->
          </table>
        </div>
      </div>
    </section>

    <section style="background:#B0DAF4;">
      <div class="wrap-box">
        <div class="structure-table-block">
          <table class="table-abonents">
            <tr class="table-header">
              <td>№ п/п</td>
              <td>П.І.Б.</td>
              <td>Завідує</td>
              <td>Адреса</td>
            </tr>
            <tr v-for="row in departmentsTable" :key="row.tableIndex">
              <td>{{row.tableIndex}}</td>
              <td>{{row.fullName}}</td>
              <td>{{row.department}}</td>
              <td>{{row.adress}}</td>
            </tr>

            <!-- <tr>
              <td>1</td>
              <td>Грибінченко Вікторія Анатоліївна</td>
              <td>Амбулаторія №1</td>
              <td>вул. Драйзера Теодора 19</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Дарага Наталія</td>
              <td>Амбулаторія №2</td>
              <td>вул. Драйзера Теодора 19</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Козелкова Людмила Іванівна</td>
              <td>Амбулаторії №3, №6</td>
              <td>
                вул. Радосинська 18;
                <br />вул. Бальзака Оноре 22;
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Музиченко Тетяна Володимирівна</td>
              <td>Амбулаторії №4</td>
              <td>вул. Драйзера Теодора 19</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Ляшиченко Інна Миколаївна</td>
              <td>Амбулаторії №5</td>
              <td>вул. Драйзера Теодора 19</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Гуменюк Наталія Миколаївна</td>
              <td>Амбулаторії №7</td>
              <td>просп. Маяковського 18А</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Турчин Галина Вікторівна</td>
              <td>Амбулаторії №8</td>
              <td>просп. Маяковського 18А</td>

            </tr>-->
          </table>
        </div>
      </div>
    </section>

    <div class="wrap-box">
      <h3 class="heading">Графік роботи</h3>
      <div class="structure-scedule-block">
        <p class="scedule-item">
          <span>Режим роботи Центру:</span>
          <br />понеділок – п’ятниця 08:00 – 20:00
        </p>
        <p class="scedule-item">
          <span>Адміністративно-управлінський персонал:</span>
          <br />понеділок – п’ятниця 08:30 – 17:00 обідня перерва: 13:00 - 13:30
        </p>
        <p class="scedule-item">
          <span>Черговий кабінет (вул. Рональда Рейгана, 19 кабінет 107):</span>
          <br />понеділок – п’ятниця 08:00 – 20:00 в вихідні дні і святкові дні
          з 9:00 – 15:00
        </p>
        <div class="announce-block">
          Якщо ви проживаєте в районі обслуговування КНП «ЦПМСД № 2»
          Деснянського району м. Києва і не маєте підписаної декларації з
          сімейним лікарем, ви можете звернутися за медичною допомогою до
          чергового кабінету. В робочі дні – з 8:00 – 20:00; в вихідні та
          святкові дні – з 9:00 – 15:00.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  name: "Structure",
  data: () => ({
    bossesTable: [
      // {
      //   title: "Директор",
      //   cabinet: "629",
      //   phoneNumber: "(073) 537-15-10",
      //   _id: "617aee27c3cf152cc4252d4d",
      //   tableIndex: 1,
      //   fullName: "Трушкіна Людмила Іванівна",
      //   __v: 0
      // },
      // {
      //   title: "Заступник директора з медичного обслуговування",
      //   cabinet: "627",
      //   phoneNumber: "(073) 537-10-08",
      //   _id: "617aee9dc3cf152cc4252d54",
      //   tableIndex: 2,
      //   fullName: "Гоменюк Ірина Анатоліївна",
      //   __v: 0
      // },
      // {
      //   title:
      //     "Заступник директора з експертизи тимчасової непрацездатності та якості лікування",
      //   cabinet: "220",
      //   phoneNumber: "(073) 537-10-34",
      //   _id: "617aeee2c3cf152cc4252d57",
      //   tableIndex: 3,
      //   fullName: "Куртяк Оксана Аркадіївна",
      //   __v: 0
      // },
      // {
      //   title: "Заступник директора з економіки",
      //   cabinet: "604",
      //   phoneNumber: "(073) 537-10-02",
      //   _id: "617aef17c3cf152cc4252d59",
      //   tableIndex: 4,
      //   fullName: "Новікова Валентина Іванівна",
      //   __v: 0
      // }
    ],
    departmentsTable: []
  }),
  components: {
    Navigation
  },
  created() {
    this.loadStructure();
    this.loadDepartments();
  },
  methods: {
    async loadStructure() {
      const data = await fetch("/api/v1/bosses/?sort=tableIndex");
      const decodedData = await data.json();
      if (decodedData.status === "success") this.bossesTable = decodedData.data;
    },
    async loadDepartments() {
      const data = await fetch("/api/v1/departments/?sort=tableIndex");
      const decodedData = await data.json();
      if (decodedData.status === "success")
        this.departmentsTable = decodedData.data;
    }
  }
};
</script>
<style scoped>
.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

/* main.contacts {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 20px auto 60px auto;
  align-items: flex-start;
} */

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.structure-table-block,
.structure-scedule-block {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 10px auto 5vh auto;
  padding: 30px;
  box-shadow: 3px 5px 13px 0 rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  overflow: hidden;
  background: #e3e3e3;
}

table.table-abonents {
  font-size: 17px;
  width: 100%;
  text-align: center;
  margin: 5px 0;
  line-height: 130%;
  /* border-collapse: collapse; */
}

table.table-abonents .table-header {
  font-size: 19px;
  width: 100%;
  font-weight: bold;
  line-height: 130%;
  border: 1px solid #4e5154;
  border-collapse: collapse;
}

table.table-abonents .table-header td {
  background: white;
}

table.table-abonents tr {
  transition: 0.3s;
}

table.table-abonents tr td {
  border-collapse: initial;
  /* border-color: #4e5154; */
  /* border-color: #6ab9df; */
  border-color: #3f4c73;
  border-width: 1px;
  border-style: solid;
  padding: 5px !important;

  background: #f5f5f5;
}

table.table-abonents tr td:nth-of-type(1) {
  width: 30px;
}
table.table-abonents tr td:nth-of-type(2) {
  width: 40%;
}
table.table-abonents tr td:nth-of-type(3) {
  width: 30px;
}
table.table-abonents tr td:nth-of-type(4) {
  width: 30%;
}
table.table-abonents tr td:nth-of-type(5) {
  width: 15%;
}

.scedule-item {
  text-align: left;
  font-size: 17px;
  margin-bottom: 11px;
  margin-left: 20px;
}

.scedule-item span {
  font-weight: bold;
  font-size: 18px;
  margin-left: -20px;
}

.announce-block {
  border: 2px solid #5b9bd5;
  margin: 20px 10px;
  padding: 10px;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
}

@media screen and (max-width: 920px) {
  .structure-table-block {
    overflow-x: scroll;
  }

  table.table-abonents {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin: 5px 0;
    line-height: 130%;
    border-collapse: collapse;
    width: 871px;
  }

  table.table-abonents .table-header {
    font-size: 18px;
    width: 100%;
    font-weight: bold;
    line-height: 130%;
    border: 1px solid #4e5154;
    border-collapse: collapse;
  }

  table.table-abonents .table-header td {
    border-collapse: initial;
    border-color: #4e5154;
    border-width: 1px;
    border-style: solid;
  }

  table.table-abonents tr td {
    border-collapse: initial;
    border-color: #4e5154;
    border-width: 1px;
    border-style: solid;
  }

  table.table-abonents tr td:nth-of-type(1) {
    width: 30px;
  }
  table.table-abonents tr td:nth-of-type(2) {
    width: 293px;
  }
  table.table-abonents tr td:nth-of-type(3) {
    width: 30px;
  }
  table.table-abonents tr td:nth-of-type(4) {
    width: 218px;
  }
  table.table-abonents tr td:nth-of-type(5) {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  h3.heading {
    margin: 20px auto 20px 30px;
    font-size: 28px;
  }

  .structure-table-block p span {
    font-size: 17px;
  }

  .announce-block {
    border: 2px solid #5b9bd5;
    margin: 20px 0;
    padding: 8px;
    font-size: 17px;
    text-align: left;
    font-weight: 700;
  }
}
</style>
